// Home page with links to the about and contact pages
import React, { useState, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import menu from "./images/menu.svg";
import cross from "./images/cross.svg";
import cross2 from "./images/cross2.svg";
import rightArrow from "./images/right_arrow.svg";
import building from "./images/building.svg";
import hero1 from "./images/artworks/1/hero.jpg";
import body1_1 from "./images/artworks/1/body1.jpg";
import body1_2a from "./images/artworks/1/body2a.jpg";
import body1_2b from "./images/artworks/1/body2b.jpg";
import body1_3 from "./images/artworks/1/body3.jpg";
import body1_4a from "./images/artworks/1/body4a.jpg";
import body1_4b from "./images/artworks/1/body4b.jpg";
import hero2 from "./images/artworks/2/hero.jpg";
import body2_1 from "./images/artworks/2/body1.jpg";
import body2_2 from "./images/artworks/2/body2.jpg";
import hero3 from "./images/artworks/3/hero.jpg";
import body3_1 from "./images/artworks/3/body1.jpg";
import hero4 from "./images/artworks/4/hero.jpg";
import body4_1 from "./images/artworks/4/body1.jpg";
import hero5 from "./images/artworks/5/hero.jpg";
import body5_1a from "./images/artworks/5/body1a.jpg";
import body5_1b from "./images/artworks/5/body1b.jpg";
import body5_2 from "./images/artworks/5/body2.jpg";
import hero6 from "./images/artworks/6/hero.jpg";
import body6_1 from "./images/artworks/6/body1.jpg";
import hero7 from "./images/artworks/7/hero.jpg";
import body7_1 from "./images/artworks/7/body1.jpg";
import body7_2 from "./images/artworks/7/body2.jpg";
import hero8 from "./images/artworks/8/hero.jpg";
import body8_1 from "./images/artworks/8/body1.jpg";
import body8_2 from "./images/artworks/8/body2.jpg";
import hero9 from "./images/artworks/9/hero.jpg";
import body9_1 from "./images/artworks/9/body1.jpg";
import body9_2 from "./images/artworks/9/body2.jpg";
import hero10 from "./images/artworks/10/hero.jpg";
import hero11 from "./images/artworks/11/hero.jpg";
import body11_1a from "./images/artworks/11/body1a.jpg";
import body11_1b from "./images/artworks/11/body1b.jpg";
import body11_2a from "./images/artworks/11/body2a.jpg";
import body11_2b from "./images/artworks/11/body2b.jpg";
import body11_3 from "./images/artworks/11/body3.jpg";
import body11_4a from "./images/artworks/11/body4a.JPG";
import body11_4b from "./images/artworks/11/body4b.JPG";
import hero12 from "./images/artworks/12/hero.jpg";
import body12_1 from "./images/artworks/12/body1.jpg";
import body12_2 from "./images/artworks/12/body2.JPG";
import body12_3a from "./images/artworks/12/body3a.JPG";
import body12_3b from "./images/artworks/12/body3b.JPG";
import hero1_LL from "./images/artworks/1/lazyLoad/hero.jpg";
import body1_1_LL from "./images/artworks/1/lazyLoad/body1.jpg";
import body1_2a_LL from "./images/artworks/1/lazyLoad/body2a.jpg";
import body1_2b_LL from "./images/artworks/1/lazyLoad/body2b.jpg";
import body1_3_LL from "./images/artworks/1/lazyLoad/body3.jpg";
import hero2_LL from "./images/artworks/2/lazyLoad/hero.jpg";
import body2_1_LL from "./images/artworks/2/lazyLoad/body1.jpg";
import body2_2_LL from "./images/artworks/2/lazyLoad/body2.jpg";
import hero3_LL from "./images/artworks/3/lazyLoad/hero.jpg";
import body3_1_LL from "./images/artworks/3/lazyLoad/body1.jpg";
import hero4_LL from "./images/artworks/4/lazyLoad/hero.jpg";
import body4_1_LL from "./images/artworks/4/lazyLoad/body1.jpg";
import hero5_LL from "./images/artworks/5/lazyLoad/hero.jpg";
import body5_1a_LL from "./images/artworks/5/lazyLoad/body1a.jpg";
import body5_1b_LL from "./images/artworks/5/lazyLoad/body1b.jpg";
import body5_2_LL from "./images/artworks/5/lazyLoad/body2.jpg";
import hero6_LL from "./images/artworks/6/lazyLoad/hero.jpg";
import body6_1_LL from "./images/artworks/6/lazyLoad/body1.jpg";
import hero7_LL from "./images/artworks/7/lazyLoad/hero.jpg";
import body7_1_LL from "./images/artworks/7/lazyLoad/body1.jpg";
import body7_2_LL from "./images/artworks/7/lazyLoad/body2.jpg";
import hero8_LL from "./images/artworks/8/lazyLoad/hero.jpg";
import body8_1_LL from "./images/artworks/8/lazyLoad/body1.jpg";
import body8_2_LL from "./images/artworks/8/lazyLoad/body2.jpg";
import hero9_LL from "./images/artworks/9/lazyLoad/hero.jpg";
import body9_1_LL from "./images/artworks/9/lazyLoad/body1.jpg";
import body9_2_LL from "./images/artworks/9/lazyLoad/body2.jpg";
import hero10_LL from "./images/artworks/10/lazyLoad/hero.jpg";
import hero13 from "./images/artworks/13/hero.JPG";
import body13_1a from "./images/artworks/13/body1a.JPG";
import body13_1b from "./images/artworks/13/body1b.JPG";
import hero14 from "./images/artworks/14/hero.JPG";
import body14_1 from "./images/artworks/14/body1.JPG";
import hero15 from "./images/artworks/15/hero.JPG";
import body15_1 from "./images/artworks/15/body1.JPEG";
import body15_2a from "./images/artworks/15/body2a.JPG";
import body15_2b from "./images/artworks/15/body2b.JPG";
import hero16 from "./images/artworks/16/hero.JPG";
import body16_1 from "./images/artworks/16/body1.jpg";
import body16_2a from "./images/artworks/16/body2a.JPG";
import body16_2b from "./images/artworks/16/body2b.JPG";
import body16_3a from "./images/artworks/16/body3a.JPG";
import body16_3b from "./images/artworks/16/body3b.JPG";
import body16_4 from "./images/artworks/16/body4.JPG";
import body16_5 from "./images/artworks/16/body5.jpeg";
import hero17 from "./images/artworks/17/hero.png";
import body17_1a from "./images/artworks/17/body1a.png";
import body17_1b from "./images/artworks/17/body1b.png";
import body17_2 from "./images/artworks/17/body2.png";
import body17_3 from "./images/artworks/17/body3.jpg";
import profile from "./images/profile/profile.png";
import profile2 from "./images/profile/profile2.png";
import exhibition1 from "./images/exhibitions/1.png";
import exhibition2 from "./images/exhibitions/2.png";
import exhibition3 from "./images/exhibitions/3.jpg";
import exhibition4 from "./images/exhibitions/4.jpg";
import exhibition5 from "./images/exhibitions/5.jpg";
import exhibition6 from "./images/exhibitions/6.jpg";
import exhibition7 from "./images/exhibitions/7.png";
import exhibition8 from "./images/exhibitions/8.jpg";
import exhibition9 from "./images/exhibitions/9.jpg";
import exhibition10 from "./images/exhibitions/10.jpeg";
import exhibition11 from "./images/exhibitions/11.jpeg";
import exhibition12 from "./images/exhibitions/12.png";
import exhibition13 from "./images/exhibitions/13.jpg";
import exhibition15 from "./images/exhibitions/15.jpg";
import exhibition16 from "./images/exhibitions/16.jpg";
import exhibition17 from "./images/exhibitions/17.png";
import exhibition18 from "./images/exhibitions/18.jpg";
import exhibition19 from "./images/exhibitions/19.jpg";
import exhibition20 from "./images/exhibitions/20.png";
import exhibition21 from "./images/exhibitions/21.JPG";
import mail from "./images/mail.svg";
import insta from "./images/insta.svg";
import cv from "./images/CV_Jihyun_Kim.pdf";
import back from "./images/back.svg";

function App() {
  const exhibitions = [
    {
      title: "(UN)KNOWN TERRITORIES; Fondazione Officine Saffi Award",
      location: "Fondazione Officine Saffi, Milan, Italy",
      date: "29th May - 31st July",
      image: exhibition17,
    },
    {
      title: "Makers in Residence: Scotland",
      location: "Material Source Studio Glasgow, UK",
      date: "May - August 2024",
      image: exhibition18,
    },
    {
      title: "Secret Ceramics for London Craft Week 2024",
      location: "Soshiro Gallery, London, UK",
      date: "15th - 19th May 2024",
      image: exhibition19,
    },
    {
      title: "Cluster Craft for London Craft Week 2024",
      location: "N1 6AH, London, UK",
      date: "16th - 19th May, 2024",
      image: exhibition20,
    },
    {
      title: "Collect 2024",
      location: "Somerset House, London, UK",
      date: "28th February - 3rd March, 2024",
      image: exhibition21,
    },
    {
      title: "Winter Exhibition 2023/2024",
      location: "Thrown Contemporary, London, UK",
      date: "16th November 2023 - 28th January 2024",
      image: exhibition15,
    },
    {
      title: "Our Anthology - A Ceramic exhibition from Korea to Denmark",
      location:
        "Gallery MEME, Seoul, South Korea | CLAY Museum, Middelfart, Denmark | Gallery Format Artspace, Copenhagen, Denmark",
      date: "September - April, 2023 - 2024",
      image: exhibition8,
    },
    {
      title: "Alchemy of Form, London Design Festival 2023",
      location: "Mint Gallery, London, UK",
      date: "16th - 30th September, 2023",
      image: exhibition13,
    },
    {
      title: "British Ceramic Biennial 2023",
      location: "All Saints Church, Stoke on Trent, UK",
      date: "September - November, 2023",
      image: exhibition16,
    },
    {
      title: "London Design Festival 2023",
      location: "OXO Tower, London, UK",
      date: "20th - 23rd September, 2023",
      image: exhibition9,
    },
    {
      title: "PRPT(PromptSet): Table Service",
      location: "Seoul, South Korea",
      date: "6th - 10th September, 2023",
      image: exhibition10,
    },
    {
      title: "Aesthetic of Bliss",
      location: "67 York Street, London, UK",
      date: "31st July - 4th August, 2023",
      image: exhibition11,
    },
    {
      title: "Light & Shadow Soho Salon Series #6",
      location: "New York, US",
      date: "June, 2023",
      image: exhibition12,
    },
    //
    {
      title: "Clerkenwell Design Week",
      location: "Trouping colour, London",
      date: "23rd - 25th May, 2023",
      image: exhibition1,
    },
    {
      title: "Milan Design Week 2023",
      location: "Isola Design Gallery, Milano",
      date: "18th - 23rd April 2023",
      image: exhibition2,
    },
    {
      title: "WIP group exhibition",
      location: "7 Wetherby Gardens, London",
      date: "16th - 26th February 2023",
      image: exhibition3,
    },
    {
      title: "Fortune Teller group exhibition",
      location: "Fronteer Gallery, Sheffield",
      date: "8th - 25th February 2023",
      image: exhibition4,
    },
    {
      title: "Metamorphosis, Multi disciplinary group exhibition",
      location: "Amp Studios, London",
      date: "11th September 2022",
      image: exhibition5,
    },
    {
      title: "WIP group exhibition",
      location: "Pumphouse gallery, London",
      date: "20th - 22nd May, 2022",
      image: exhibition6,
    },
    {
      title: "Seoul International Handmade Fair 2019",
      location: "Dongdaemun Design Plaza, Seoul",
      date: "16th - 19th May, 2019",
      image: exhibition7,
    },
  ];

  const works = [
    {
      title: "Terracotta Loop",
      year: "2024",
      mainImage: hero17,
      lazyLoad: hero17,
      materials: "Terracotta, High-fired colour stain, Gloop glaze",
      body: [
        {
          type: "image_pair",
          content: [body17_1a, body17_1b],
          lazyLoad: [body17_1a, body17_1b],
        },
        {
          type: "image",
          content: body17_2,
          lazyLoad: body17_2,
        },
        {
          type: "image",
          content: body17_3,
          lazyLoad: body17_3,
        },
        {
          type: "text",
          content:
            "This edition is a ceramic series inspired by the Korean tradition of Geumjul(금줄). Geumjul is the ancient Korean practice of hanging straw ropes to celebrate births and ward off evil spirits. Jihyun re-interpreted this tradition into contemporary ceramic pieces with a modern twist, capturing the spirit of cultural heritage in a fresh, innovative form.",
        },
        {
          type: "text",
          content: "This work is commissioned by Relay Design Agency.",
        },
        {
          type: "link",
          link: "https://relaydesign.info/collections",
          placeholder: "Relay Design Agency",
        },
      ],
    },
    {
      title: "Salty Fairy Ring 2024 Spring Collection",
      year: "2024",
      mainImage: hero16,
      lazyLoad: hero16,
      materials: "Porcelain, High-fired colour stain, Gloop glaze, Wool",
      body: [
        {
          type: "image",
          content: body16_1,
          lazyLoad: body16_1,
        },
        {
          type: "image_pair",
          content: [body16_2a, body16_2b],
          lazyLoad: [body16_2a, body16_2b],
        },
        {
          type: "image_pair",
          content: [body16_3a, body16_3b],
          lazyLoad: [body16_3a, body16_3b],
        },
        {
          type: "image",
          content: body16_4,
          lazyLoad: body16_4,
        },
        {
          type: "image",
          content: body16_5,
          lazyLoad: body16_5,
        },
        {
          type: "text",
          content:
            "Jihyun created a new collection of Salty Fairy Rings at Glasgow Ceramic Studio during her internship. The works were created from December of 2023 to March of 2024. This collection is available at the Cube Gallery in London.",
        },
        {
          type: "link",
          link: "https://www.cube-gallery.co.uk/",
          placeholder: "Cube Gallery",
        },
      ],
    },
    {
      title: "Salty Fairy Vase Collection Collect",
      year: "2024",
      mainImage: hero15,
      lazyLoad: hero15,
      materials: "Porcelain, High-fired colour stain, Gloop glaze",
      body: [
        {
          type: "image",
          content: body15_1,
          lazyLoad: body15_1,
        },
        {
          type: "image_pair",
          content: [body15_2a, body15_2b],
          lazyLoad: [body15_2a, body15_2b],
        },
        {
          type: "text",
          content:
            "This collection is currently available at the Cube Gallery London.",
        },
        {
          type: "link",
          link: "https://www.cube-gallery.co.uk/",
          placeholder: "Cube Gallery",
        },
      ],
    },
    {
      title: "Salty Fairy Vase Collection Milan",
      year: "2024",
      mainImage: hero14,
      lazyLoad: hero14,
      materials: "Porcelain, High-fired colour stain, Gloop glaze",
      body: [
        {
          type: "image",
          content: body14_1,
          lazyLoad: body14_1,
        },
        {
          type: "text",
          content:
            "This work is available at the Fodazione Officine Saffi in Milan.",
        },
        {
          type: "link",
          link: "https://officinesaffi.org/en/award/award-5",
          placeholder: "Fondazione Officine Saffi",
        },
      ],
    },
    {
      title: "Ring Totem",
      year: "2023",
      mainImage: hero13,
      lazyLoad: hero13,
      materials: "Porcelain, High-fired colour stain, Gloop glaze",
      body: [
        {
          type: "image_pair",
          content: [body13_1a, body13_1b],
          lazyLoad: [body13_1a, body13_1b],
        },
      ],
    },
    {
      title: "Guidance Lichen",
      year: "2023",
      mainImage: hero12,
      lazyLoad: hero12,
      materials: "Porcelain, High-fired colour stain, Gloop glaze, Wool",
      body: [
        {
          type: "image",
          content: body12_1,
          lazyLoad: body12_1,
        },
        {
          type: "image",
          content: body12_2,
          lazyLoad: body12_2,
        },
        {
          type: "image_pair",
          content: [body12_3a, body12_3b],
          lazyLoad: [body12_3a, body12_3b],
        },
        {
          type: "text",
          content:
            "This is the candle holder that was derived from a Korean traditional ritual for ancestors and the work is named, ‘Guidance Lichen’. The majority of the Korean family does a ritual every year to thank luck and wish happiness for their ancestors and invite their spirits to the house and serve them food. The smoke of the candle acts as a navigator for the ancestor to find the house and Jihyun incorporated the story with the healing property and important ecological symbiotic role of lichen.",
        },
      ],
    },
    {
      title: "Fingernail Fungi",
      year: "2023",
      mainImage: hero11,
      lazyLoad: hero11,
      materials: "Porcelain, High-fired colour stain, Experimental glaze",
      body: [
        {
          type: "text",
          content:
            "The inspiration for 'Fingernail Fungi' came from Korean folklore about fingernails and the mouse. Jihyun's grandmother always told her not to cut her fingernails in the night because the mouse will eat them and become her. She incorporated this mystical tale with the poisonous and hand-like appearance of the Red coral fungi. The dangerous properties of the mushroom serve as the driving force behind the artwork, ensuring that the piece not only contains fingernails but also safeguards them from the covetous appetite of mice.",
        },
        {
          type: "image_pair",
          content: [body11_1a, body11_1b],
          lazyLoad: [body11_1a, body11_1b],
        },
        {
          type: "image_pair",
          content: [body11_2a, body11_2b],
          lazyLoad: [body11_2a, body11_2b],
        },
        {
          type: "image",
          content: body11_3,
          lazyLoad: body11_3,
        },
        {
          type: "image_pair",
          content: [body11_4a, body11_4b],
          lazyLoad: [body11_4a, body11_4b],
        },
      ],
    },
    {
      title: "Salty fairy ring",
      year: "2023",
      mainImage: hero1,
      lazyLoad: hero1_LL,
      materials: "Porcelain, High-fired colour stain, Gloop glaze",
      body: [
        {
          type: "text",
          content:
            "This body of work was inspired by her grandmother's ritual of placing a small ceramic jar filled with salt near the entrance of their South Korean home. This jar was believed to prevent negative energy and evil spirits from entering the household, and it is a widely held belief and superstition in South Korea.",
        },
        {
          type: "image",
          content: body1_1,
          lazyLoad: body1_1_LL,
        },
        {
          type: "text",
          content:
            "Jihyun merged this tradition with the enchanting properties of fairy rings, which are circular formations of mushrooms that have been interpreted in various ways throughout history. Drawing inspiration from these two sources, Jihyun created a series of salt containers, aptly named 'Salty Fairy Ring' which embody a sense of wonder and magic.",
        },
        {
          type: "image_pair",
          content: [body1_4a, body1_4b],
          lazyLoad: [body1_4a, body1_4b],
        },
        {
          type: "text",
          content:
            "These intricately crafted pieces are designed to serve as a spiritual sanctuary and a magical borderline, evoking a sense of intrigue and fascination in the viewer. Jihyun's use of slip casting, along with her incorporation of various colours and decorating techniques, imbues each jar with its own unique charm and personality. The addition of gloop glaze as a stem creates a playful, organic look that perfectly complements the jars' mystical properties.",
        },
        {
          type: "image_pair",
          content: [body1_2a, body1_2b],
          lazyLoad: [body1_2a_LL, body1_2b_LL],
        },
        {
          type: "image",
          content: body1_3,
          lazyLoad: body1_3_LL,
        },
      ],
    },
    // {
    //   title: "Cycle over serendipity",
    //   year: "2022",
    //   mainImage: hero2,
    //   lazyLoad: hero2_LL,
    //   materials: "Porcelain, High-fired colour stain, Gloop glaze",
    //   body: [
    //     {
    //       type: "text",
    //       content:
    //         "Collaboration work with Izu Song (Ceramic units done by Izu)",
    //     },
    //     {
    //       type: "text",
    //       content:
    //         "The accumulation of each fleeting moment creates this grand cycle.  Ultimately, life is sustained by the fleeting and poignant moments that comprise our existence. Jihyun and Izu aimed to capture a moment of beauty through the unpredictable and chance nature of glaze.",
    //     },
    //     {
    //       type: "image",
    //       content: body2_1,
    //       lazyLoad: body2_1_LL,
    //     },
    //     {
    //       type: "image",
    //       content: body2_2,
    //       lazyLoad: body2_2_LL,
    //     },
    //   ],
    // },
    // {
    //   title: "Gloop sky jar",
    //   year: "2022",
    //   mainImage: hero3,
    //   lazyLoad: hero3_LL,
    //   materials: "Crack clay, Underglaze, Gloop glaze, High-fired colour stain",
    //   body: [
    //     {
    //       type: "image",
    //       content: body3_1,
    //       lazyLoad: body3_1_LL,
    //     },
    //   ],
    // },
    // {
    //   title: "Coconut Gloop goblet",
    //   year: "2022",
    //   mainImage: hero4,
    //   lazyLoad: hero4_LL,
    //   materials: "Terracotta, Gloop glaze",
    //   body: [
    //     {
    //       type: "image",
    //       content: body4_1,
    //       lazyLoad: body4_1_LL,
    //     },
    //   ],
    // },
    // {
    //   title: "Sweet pills",
    //   year: "2022",
    //   mainImage: hero5,
    //   lazyLoad: hero5_LL,
    //   materials: "Terracotta, Gloop glaze, Underglaze, Tin-oxide",
    //   body: [
    //     {
    //       type: "image",
    //       content: body5_1a,
    //       lazyLoad: body5_1a_LL,
    //     },
    //     {
    //       type: "image",
    //       content: body5_1b,
    //       lazyLoad: body5_1b_LL,
    //     },
    //     {
    //       type: "image",
    //       content: body5_2,
    //       lazyLoad: body5_2_LL,
    //     },
    //   ],
    // },
    // {
    //   title: "Do not rest on your laurels",
    //   year: "2022",
    //   mainImage: hero6,
    //   lazyLoad: hero6_LL,
    //   materials: "Terracotta",
    //   body: [
    //     {
    //       type: "image",
    //       content: body6_1,
    //       lazyLoad: body6_1_LL,
    //     },
    //   ],
    // },
    // {
    //   title: "Shroom case",
    //   year: "2020",
    //   mainImage: hero7,
    //   lazyLoad: hero7_LL,
    //   materials: "Porcelain, High-fired colour stain, Gold stain",
    //   body: [
    //     {
    //       type: "text",
    //       content:
    //         "Shroom case is inspired the appearence of Puffball mushroom which has tiny bumps on its surface. They are colourful mushroom cases standing with three fingers.",
    //     },
    //     {
    //       type: "image",
    //       content: body7_1,
    //       lazyLoad: body7_1_LL,
    //     },
    //     {
    //       type: "image",
    //       content: body7_2,
    //       lazyLoad: body7_2_LL,
    //     },
    //   ],
    // },
    // {
    //   title: "Shroom lamp",
    //   year: "2020",
    //   mainImage: hero8,
    //   lazyLoad: hero8_LL,
    //   materials: "Porcelain, High-fired colour stain, Gold, Felt",
    //   body: [
    //     {
    //       type: "text",
    //       content:
    //         "Shroom lamp is inspired by the appearence of a puffball mushroom which has tiny bumps in it's surface.",
    //     },
    //     {
    //       type: "image",
    //       content: body8_1,
    //       lazyLoad: body8_1_LL,
    //     },
    //     {
    //       type: "image",
    //       content: body8_2,
    //       lazyLoad: body8_2_LL,
    //     },
    //   ],
    // },
    // {
    //   title: "Shroom pipe",
    //   year: "2020",
    //   mainImage: hero9,
    //   lazyLoad: hero9_LL,
    //   materials: "Porcelain, High-fired colour stain, Resin",
    //   body: [
    //     {
    //       type: "text",
    //       content:
    //         "Shroom pipe was inspired by the appearence of the Rhodotus mushroom which has opaque liquid flowing from the stem.",
    //     },
    //     {
    //       type: "image",
    //       content: body9_1,
    //       lazyLoad: body9_1_LL,
    //     },
    //     {
    //       type: "image",
    //       content: body9_2,
    //       lazyLoad: body9_2_LL,
    //     },
    //   ],
    // },
    // {
    //   title: "The search of my animalistic ego",
    //   year: "2019",
    //   mainImage: hero10,
    //   lazyLoad: hero10_LL,
    //   materials: "Porcelain, High-fired colour stain, Gold stain",
    //   body: [
    //     {
    //       type: "text",
    //       content:
    //         "As you draw closer to the psychedelic mushroom, entranced by its flamboyance, the grotesque details will capture the attention. The work represents my contradictory nature of trying to live by society’s rules, while harboring violent instincts. On a larger scale, it illustrates all misfits struggling in the juxtaposition of two contrasting emotions: the want to fit in, and the want to act out.",
    //     },
    //   ],
    // },
  ];

  const [menuOpen, setMenuOpen] = useState(false);
  const [page, setPage] = useState("home");
  const [work, setWork] = useState(null);

  // constantly update the inner width of the window wheneever the window is resized
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // useEffect to update the window width when the window is resized
  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);

  // set the square width based on the screen width, at different breakpoints
  const [squareWidth, setSquareWidth] = useState(windowWidth);
  const [fullimgWidth, setFullimgWidth] = useState(windowWidth);
  const [squareMarg, setSquareMarg] = useState(48);
  useEffect(() => {
    if (windowWidth < 1024) {
      setSquareWidth(windowWidth);
      setFullimgWidth(windowWidth);
      setSquareMarg(48);
      console.log("small");
    } else if (windowWidth < 1280) {
      setSquareWidth(272);
      setFullimgWidth(600);
      setSquareMarg(0);
      console.log("medium");
    } else {
      setSquareWidth(272);
      setFullimgWidth(600);
      setSquareMarg(0);
      console.log("large");
    }
  }, [windowWidth]);

  //   a function which returns an html element which is a div containing an image. The image should be cropped to a square shape, where the dimension and image are the inputs
  function SquareImage({ dimension, image, margin }) {
    return (
      <div
        style={{
          width: dimension - margin * 2,
          height: dimension - margin * 2,
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          "background-color": "#eeeeee",
        }}
        className="mx-auto"
      />
    );
  }

  const menuItems = [
    {
      title: "Works",
      onClick: "home",
    },
    {
      title: "About",
      onClick: "about",
    },
    {
      title: "Exhibitions",
      onClick: "exhibitions",
    },
    {
      title: "Contact",
      onClick: "contact",
    },
  ];

  // Create menu item component
  function menuItem({ title, onClick, currentPage }) {
    return (
      <h1
        className={`text-right text-lg font-light cursor-pointer ${
          currentPage === onClick ? "font-semibold" : ""
        }`}
        onClick={() => {
          setPage(onClick);
          document.body.style.overflow = "scroll";
        }}
      >
        {title}
      </h1>
    );
  }

  // Create header component
  function Header() {
    return (
      <div className="flex flex-row justify-between mx-10 lg:mx-auto mt-11 lg:mt-20 lg:w-lgW">
        <div className="absolute -ml-40 w-32 lg:flex flex-col hidden">
          {menuItems.map((item) => {
            return menuItem({
              title: item.title,
              onClick: item.onClick,
              currentPage: page,
            });
          })}
        </div>
        <h1
          className="text-4xl lg:text-5xl cursor-pointer"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            if (page !== "home") {
              setPage("home");
            }
            if (work !== null) {
              setWork(null);
            }
          }}
        >
          Jihyun Kim
        </h1>
        {!menuOpen && (
          <img
            src={menu}
            alt="menu"
            onClick={() => setMenuOpen(!menuOpen)}
            className="lg:hidden"
            draggable="false"
          />
        )}
      </div>
    );
  }

  // Create works component, which loops through the works array and displays each work
  function Works() {
    return (
      <>
        <div className="cursor-pointer px-12 lg:px-0 pt-8 w-full lg:flex lg:pt-10 lg:w-lgW lg:mx-auto lg:flex-row lg:flex-wrap lg:justify-between">
          {/* <h1>Works</h1> */}
          {works.map((work) => (
            <div
              className="pb-6 lg:pb-8 lg:w-68"
              //   onclick navigate to work page
              onClick={() => {
                setPage("work");
                setWork(work);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              <SquareImage
                dimension={squareWidth}
                image={work.mainImage}
                margin={squareMarg}
              />
              <h2 className="italic font-light pt-2 md:text-xl lg:text-lg">
                {work.title}
              </h2>
            </div>
          ))}
        </div>
      </>
    );
  }

  // Create footer component
  function Footer() {
    return (
      <div>
        <h1
          className="text-center pb-6 cursor-pointer"
          //   onclick animate to top of page smoothly
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            if (page !== "home") {
              setPage("home");
            }
            if (work !== null) {
              setWork(null);
            }
          }}
        >
          Jihyun Kim • 2024
        </h1>
      </div>
    );
  }

  // Create menu component if the menu has been clicked
  function Menu() {
    if (menuOpen) {
      // disable scrolling when menu is open
      // document.body.style.overflow = "hidden";

      return (
        <div className="absolute top-0 left-0 w-full h-full bg-white z-10">
          <div className="flex flex-row justify-between mx-10 mt-11">
            <h1
              className="text-4xl text-left"
              onClick={() => {
                setPage("home");
                setMenuOpen(!menuOpen);
                // document.body.style.overflow = "scroll";
              }}
            >
              Jihyun Kim
            </h1>
            {menuOpen && (
              <img
                src={cross}
                alt="close menu"
                draggable="false"
                onClick={() => {
                  setMenuOpen(!menuOpen);
                  // document.body.style.overflow = "scroll";
                }}
              />
            )}
          </div>
          <div className="flex flex-col mx-10 mt-5">
            <h1
              className="text-3xl text-center font-light pt-5"
              onClick={() => {
                setPage("home");
                setMenuOpen(!menuOpen);
                // document.body.style.overflow = "scroll";
              }}
            >
              Works
            </h1>
            <h1
              className="text-3xl text-center font-light pt-5"
              onClick={() => {
                setPage("about");
                setMenuOpen(!menuOpen);
                // document.body.style.overflow = "scroll";
              }}
            >
              About
            </h1>
            <h1
              className="text-3xl text-center font-light pt-5"
              onClick={() => {
                setPage("exhibitions");
                setMenuOpen(!menuOpen);
                // document.body.style.overflow = "scroll";
              }}
            >
              Exhibitions
            </h1>
            <h1
              className="text-3xl text-center font-light pt-5"
              onClick={() => {
                setPage("contact");
                setMenuOpen(!menuOpen);
                // document.body.style.overflow = "scroll";
              }}
            >
              Contact
            </h1>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  //   workBody component
  function WorkBody(work) {
    // handle the body of the work component. It contains objects of type text, image, and twinImage. The type is used to determine which html element to return, and the content is displayed sequentially for the number of items in the array
    return (
      <div className="pb-7 lg:w-lgW lg:mx-auto">
        {work.work.body.map((item) => {
          if (item.type === "text") {
            return (
              <div className="pt-6">
                <p className="font-light text-sm lg:text-base">
                  {item.content}
                </p>
              </div>
            );
          } else if (item.type === "link") {
            return (
              <div className="pt-3">
                <a
                  className="font-light text-sm lg:text-base text-blue-800 underline"
                  href={item.link}
                  target="_blank"
                >
                  {item.placeholder}
                </a>
              </div>
            );
          } else if (item.type === "image") {
            return (
              <div className="pt-6">
                <LazyLoadImage
                  draggable="false"
                  src={item.content}
                  PlaceholderSrc={item.lazyLoad}
                  alt="work image"
                  className=" bg-slate-100"
                />
              </div>
            );
          } else if (item.type === "image_pair") {
            //  if the type is image_pair, return a div containing two images side by side, where the images fill the width of the screen
            return (
              <div className="flex flex-row pt-6">
                <LazyLoadImage
                  draggable="false"
                  src={item.content[0]}
                  PlaceholderSrc={item.lazyLoad[0]}
                  alt="work image"
                  className="w-1/2  bg-slate-100"
                />
                <LazyLoadImage
                  draggable="false"
                  src={item.content[1]}
                  PlaceholderSrc={item.lazyLoad[1]}
                  alt="work image"
                  className="w-1/2  bg-slate-100"
                />
              </div>
            );
          }
        })}
      </div>
    );
  }

  //   Create work page component
  function WorkPage() {
    if (work !== null) {
      return (
        <>
          <div className="hidden lg:flex flex-col w-lgW mx-auto pb-8">
            <div className="flex flex-row justify-start">
              <img
                draggable="false"
                src={back}
                className="pt-8 mr-3 cursor-pointer"
                alt="close work"
                onClick={() => {
                  setPage("home");
                  setWork(null);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              />
              <h1
                className="text-3xl font-light pt-8 italic cursor-pointer"
                onClick={() => {
                  setPage("home");
                  setWork(null);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                {work.title}
              </h1>
            </div>
            <h1 className="text font-light pt-2">{work.year}</h1>
            <h1 className="text font-light pt-1">{work.materials}</h1>
          </div>
          <SquareImage
            dimension={fullimgWidth}
            image={work.mainImage}
            margin={0}
          />
          {/* cross button in top right corner */}
          <img
            draggable="false"
            src={cross2}
            className="absolute top-7 right-7 lg:hidden"
            alt="close work"
            onClick={() => {
              setPage("home");
              setWork(null);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          />
          <div className="px-12 lg:px-0">
            <h1 className="text-2xl font-light pt-8 italic lg:hidden">
              {work.title}
            </h1>
            <h1 className="text-xs font-light pt-2 lg:hidden">{work.year}</h1>
            <h1 className="text-xs font-light pt-1 lg:hidden">
              {work.materials}
            </h1>
            <WorkBody work={work} />
          </div>
        </>
      );
    }
  }

  // Create about page component
  function AboutPage() {
    return (
      <div className="px-12 lg:px-0 pt-8 lg:pt-10 w-full lg:w-lgW lg:mx-auto">
        <div className="pb-6">
          <SquareImage
            dimension={fullimgWidth}
            image={profile}
            margin={squareMarg}
          />
        </div>
        <h1 className="text-2xl lg:text-3xl font-light italic">About</h1>
        <p className="font-light text-sm lg:text-base pt-2">
          Jihyun Kim, a ceramic designer from South Korea, melds tradition with
          enchantment in her craft. Holding a Master of Arts in Ceramics and
          Glass from the Royal College of Art, her work is internationally
          recognized, and showcased at esteemed events like Milan Design Week
          and the British Ceramic Biennale.
          <br /> <br />
          Jihyun's creations beautifully blend sculptural and functional
          elements, inspired by her cultural heritage and nature's mystical
          aspects. Her exploration of organic forms, especially fungi, infuses
          her pieces with magical narratives, inviting viewers to discover magic
          in the mundane. With meticulous attention to detail and a fusion of
          artistic form and utility, Jihyun's ceramics transcend the ordinary,
          embodying a harmonious blend of tradition and imagination.
        </p>
        <div className="pt-6">
          <SquareImage
            dimension={fullimgWidth}
            image={profile2}
            margin={squareMarg}
          />
        </div>
        <div className="bg-gray-100 flex flex-row justify-center py-4 my-7">
          <a
            href={cv}
            target="_blank"
            rel="noopener noreferrer"
            className="text-xl font-light italic"
          >
            Download CV
          </a>
        </div>
      </div>
    );
  }

  // Create exhibitions page component
  function ExhibitionsPage() {
    return (
      <div className="px-12 lg:px-0 pt-8 lg:pt-10 w-full lg:w-lgW lg:mx-auto">
        {exhibitions.map((exhibition) => (
          <div className="pb-6 lg:pb-12">
            <LazyLoadImage
              draggable="false"
              src={exhibition.image}
              alt="exhibition image"
              className="w-full lg:w-lgW lg:mx-auto h-auto  bg-slate-100"
            />
            <h2 className="text-2xl font-light italic pt-2">
              {exhibition.title}
            </h2>
            <p className="font-light text-sm pt-1 pb-2">
              {exhibition.location} <br />
              {exhibition.date}
            </p>
          </div>
        ))}
      </div>
    );
  }

  // Create contact page component
  function ContactPage() {
    return (
      <div>
        <div className="px-12 pt-8 lg:pt-10 w-full lg:w-lgW lg:mx-auto lg:px-0">
          Please don’t hesitate to contact me for any enquiries!
          <a
            className="flex flex-row pt-8"
            href="mailto:
              jihyunkim5724@gmail.com
            "
          >
            <img
              draggable="false"
              src={mail}
              alt="email icon"
              className="w-6"
            />
            <div className="text-sm ml-3">jihyunkim5724@gmail.com</div>
          </a>
          <a
            className="flex flex-row pt-4"
            href="https://www.instagram.com/kimkim_ceramic/"
          >
            <img
              draggable="false"
              src={insta}
              alt="instagram icon"
              className="w-6"
            />
            <div className="text-sm ml-3 pt-0.5">@kimkim_ceramic</div>
          </a>
        </div>
        <h1
          className="text-center absolute bottom-6 w-full"
          //   onclick animate to top of page smoothly
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            if (page !== "home") {
              setPage("home");
            }
            if (work !== null) {
              setWork(null);
            }
          }}
        >
          Jihyun Kim • 2023
        </h1>
      </div>
    );
  }

  // Create home component
  function Content() {
    if (page === "home") {
      return (
        <>
          <Menu />
          {!menuOpen && <Header />}
          {!menuOpen && <Works />}
          {!menuOpen && <Footer />}
        </>
      );
    } else if (page === "work") {
      return (
        <>
          {windowWidth >= 1024 && <Header />}
          {!menuOpen && <WorkPage />}
          {!menuOpen && <Footer />}
        </>
      );
    } else if (page === "about") {
      return (
        <>
          <Menu />
          {!menuOpen && <Header />}
          {!menuOpen && <AboutPage />}
          {!menuOpen && <Footer />}
        </>
      );
    } else if (page === "exhibitions") {
      return (
        <>
          <Menu />
          {!menuOpen && <Header />}
          {!menuOpen && <ExhibitionsPage />}
          {!menuOpen && <Footer />}
        </>
      );
    } else if (page === "contact") {
      return (
        <>
          <Menu />
          {!menuOpen && <Header />}
          {!menuOpen && <ContactPage />}
        </>
      );
    }
  }

  return (
    <div className="bg-stone-50">
      {/* <Content /> */}
      <div className="h-[100dvh] w-screen flex flex-col justify-center">
        <div className="=text-center bg-stone-100 mx-auto p-10 rounded-3xl flex flex-col gap-5">
          <img
            src={building}
            className="w-20 mx-auto bg-stone-200 p-4 rounded-full"
            draggable="false"
          />
          <div className="text-xl w-48 text-center mx-auto">
            Website under construction
          </div>
          <a
            href="https://jihyunkimceramic.squarespace.com/"
            className="transition-all duration-200 text-center font-medium bg-stone-200 hover:bg-stone-300 mx-auto rounded-full py-4 px-7 cursor-pointer flex flex-row gap-3"
          >
            Visit shop
            <img src={rightArrow} className="w-4" draggable="false" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
